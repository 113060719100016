import React, { useEffect, useRef } from 'react';

function DynamicScrollSection() {
  const sectionRef = useRef(null);
  const wordsRef = useRef([]);

  useEffect(() => {
    let isSticky = false;
    let startScrollPos = 0;
    const section = sectionRef.current;
    const words = wordsRef.current;

    const handleScroll = () => {
      const rect = section.getBoundingClientRect();
      if (rect.top <= 120 && !isSticky) {
        isSticky = true;
        startScrollPos = window.scrollY;
      } else if (rect.top > 120 && isSticky) {
        isSticky = false;
      }

      if (isSticky) {
        const currentScroll = window.scrollY - startScrollPos;
        const maxScroll = window.innerHeight * 2;
        const progress = Math.min(Math.max(currentScroll / maxScroll, 0), 1);
        
        const wordIndex = Math.min(
          Math.floor(progress * words.length),
          words.length - 1
        );

        words.forEach((word, index) => {
          if (index === wordIndex) {
            word.classList.add('active');
          } else {
            word.classList.remove('active');
          }
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section ref={sectionRef} className="dynamic-scroll">
      <h2>STRIKE IS FOR</h2>
      <div className="scrolling-words">
        {['Athletes', 'Businesses', 'Influencers', 'Dreamers'].map((word, index) => (
          <div
            key={word}
            ref={el => wordsRef.current[index] = el}
            className={`scrolling-word ${index === 0 ? 'active' : ''}`}
          >
            {word}
          </div>
        ))}
      </div>
    </section>
  );
}

export default DynamicScrollSection;