import React from 'react';
import './game.css';

const Game = () => (
  <section className="game section__padding scale-up-center">
    <h1>The Future of Marketing - Athlete Influencers</h1>
    <div className="description-with-image">
      <p className="description">Authentic. High-Impact. Built for Growth.</p>
    </div>
    <div className="info-with-image">

      <p className="additional-info">Strike is the marketplace where brands and athletes connect, collaborate, and grow. Brands tap into Gen Z consumers, secure real partnerships, and invest in emerging talent - all with low to no fees. Athletes access exclusive sponsorships, manage deals, and get paid directly.</p>
    </div>
      <p className="additional-info1">Ditch overpriced platforms. Connect directly. Market smarter. Grow faster.</p>  </section>
);

export default Game;