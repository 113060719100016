import React, { useEffect } from 'react';
import { Footer, Features, Tabs, Header, Game } from './containers';
import { Gallery, Navbar, Scroller, Scroll } from './components';
import './App.css';
import AOS from 'aos';
import useScrollToTop from './useScrollToTop';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 100,
      easing: 'ease-in-out',
      once: false,
      mirror: true,
      anchorPlacement: 'top-center',
      offset: 0,
    });
  }, []);

  useScrollToTop();

  return (
    <div className="App">
      <div className="background-container">
        <Navbar />
        <Header />
      </div>
      <Scroll />
      <Gallery />
      <Game />
      <Tabs />
      <Features />
      <Footer />
      <Scroller /> {/* Moved Scroller to the end */}
    </div>
  );
}

export default App;