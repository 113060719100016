import React, { useRef, useEffect } from 'react';
import './gallery.css';
import image from '../../assets/page2.png'; 
import image2 from '../../assets/poopfilter.png'; 

const Gallery = () => {
  const galleryRef = useRef(null);
  
  useEffect(() => {
    const gallery = galleryRef.current;
    if (!gallery) return;
    
    const galleryItems = gallery.querySelectorAll('.gallery-item');
    
    // Function to pause animation
    const pauseAnimation = () => {
      gallery.style.animationPlayState = 'paused';
    };
    
    // Function to resume animation
    const resumeAnimation = () => {
      gallery.style.animationPlayState = 'running';
    };
    
    // Add event listeners to each gallery item
    galleryItems.forEach(item => {
      // Mouse events for desktop
      item.addEventListener('mouseenter', pauseAnimation);
      item.addEventListener('mouseleave', resumeAnimation);
      
      // Touch events for mobile
      item.addEventListener('touchstart', pauseAnimation);
      item.addEventListener('touchend', resumeAnimation);
      item.addEventListener('touchcancel', resumeAnimation);
    });
    
    // Cleanup event listeners on unmount
    return () => {
      galleryItems.forEach(item => {
        // Remove mouse events
        item.removeEventListener('mouseenter', pauseAnimation);
        item.removeEventListener('mouseleave', resumeAnimation);
        
        // Remove touch events
        item.removeEventListener('touchstart', pauseAnimation);
        item.removeEventListener('touchend', resumeAnimation);
        item.removeEventListener('touchcancel', resumeAnimation);
      });
    };
  }, []);
  
  return (
    <div className="gallery-wrap scale-up-center">
      <h2>
        Join the Waitlist. First Deal, No Fees.
      </h2>
      
      {/* BRANDS SECTION */}
      <div className="section-container">
        <h1 className="gallery-header">WHAT WE DO - BRANDS</h1>
        <p className="gallery-description">Leverage Athlete Influence. Elevate Your Brand.</p>
        <div className="list-with-image">
          <img src={image} alt="Brand search filters interface" className="list-image" />
          <ul className="gallery-list">
            <li>- Find the right athletes with powerful search filters.</li>
            <li>- Customize deals with seamless contracts and payments.</li>
            <li>- Boost ROI at a fraction of the cost of other platforms.</li>
          </ul>
        </div>
      </div>

      {/* ATHLETES SECTION */}
      <div className="section-container">
        <h1 className="gallery-header1">WHAT WE DO - ATHLETES</h1>
        <p className="gallery-description1">Turn Your Influence into Income</p>
        <div className="list-with-image1">
          <img src={image2} alt="Athlete platform interface" className="list-image1" />
          <ul className="gallery-list1">
            <li>- Find real brands that fit your style and audience.</li>
            <li>- Track earnings and manage deals in one place.</li>
            <li>- Grow your brand and maximize your income.</li>
          </ul>
        </div>
      </div>
      
      <div className="gallery" ref={galleryRef}>
        <div className="gallery-group">
          <div className="gallery-item mag1"></div>
          <div className="gallery-item mag2"></div>
          <div className="gallery-item mag3"></div>
          <div className="gallery-item mag4"></div>
          <div className="gallery-item mag5"></div>
          <div className="gallery-item mag6"></div>
          <div className="gallery-item mag7"></div>
          <div className="gallery-item mag8"></div>
        </div>
        <div className="gallery-group">
          <div className="gallery-item mag1"></div>
          <div className="gallery-item mag2"></div>
          <div className="gallery-item mag3"></div>
          <div className="gallery-item mag4"></div>
          <div className="gallery-item mag5"></div>
          <div className="gallery-item mag6"></div>
          <div className="gallery-item mag7"></div>
          <div className="gallery-item mag8"></div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;