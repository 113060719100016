import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Replace useHistory with useNavigate
import './Waitlist.css';
import Footer from '../../containers/footer/Footer';
import logo from '../../assets/STRIKELOGO.png';

const Waitlist = () => {
  const navigate = useNavigate(); // Replace useHistory with useNavigate
  const [formData, setFormData] = useState({
    EMAIL: '',
    FNAME: '',
    MMERGE7: '',
    MMERGE8: '',
  });
  const [statusMessage, setStatusMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.EMAIL || !formData.FNAME || !formData.MMERGE7 || !formData.MMERGE8) {
      setStatusMessage('Please fill out all fields.');
      return;
    }

    const formPayload = new FormData();
    formPayload.append('EMAIL', formData.EMAIL);
    formPayload.append('FNAME', formData.FNAME);
    formPayload.append('MMERGE7', formData.MMERGE7);
    formPayload.append('MMERGE8', formData.MMERGE8);

    try {
      await fetch(
        'https://strikentwrk.us16.list-manage.com/subscribe/post?u=5b402a2729e8211ce684fdefd&id=a6b1c2e64e&f_id=00ea6ee0f0',
        {
          method: 'POST',
          mode: 'no-cors',
          body: formPayload,
        }
      );

      // Assume successful submission (since no-cors mode prevents checking the response)
      setStatusMessage('Welcome to the team!');
      setFormData({
        EMAIL: '',
        FNAME: '',
        MMERGE7: '',
        MMERGE8: '',
      }); // Clear the form fields
    } catch (error) {
      setStatusMessage('An error occurred. Please try again.');
    }
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <>
      <div className="waitlist-container">
        <img src={logo} alt="Strike Logo" className="strike-logo" onClick={handleLogoClick} /> {/* Add onClick */}
        <h1>It's your time to Strike.</h1>
        <div className="inline-text">
          <p className="text1">SIGN UP NOW AND GET 100% OFF COMMISSION ON YOUR FIRST DEAL.</p>
          <p className="text2">JOIN THE NETWORK.</p>
        </div>

        <form onSubmit={handleSubmit} noValidate>
          <h2>Let's Strike a Deal</h2>
          <div className="mc-field-group">
            <input
              type="email"
              name="EMAIL"
              className="waitlist-input"
              id="mce-EMAIL"
              placeholder="Email"
              required
              value={formData.EMAIL}
              onChange={handleChange}
            />
          </div>
          <div className="mc-field-group">
            <input
              type="text"
              name="FNAME"
              className="waitlist-input"
              id="mce-FNAME"
              placeholder="Full Name"
              required
              value={formData.FNAME}
              onChange={handleChange}
            />
          </div>
          <div className="mc-field-group">
            <select
              name="MMERGE7"
              id="mce-MMERGE7"
              className="waitlist-input"
              value={formData.MMERGE7}
              onChange={handleChange}
            >
              <option value="">Athlete or Business</option>
              <option value="Athlete">Athlete</option>
              <option value="Business">Business</option>
            </select>
          </div>
          <div className="mc-field-group">
            <input
              type="text"
              name="MMERGE8"
              className="waitlist-input"
              id="mce-MMERGE8"
              placeholder="School/Business Name"
              value={formData.MMERGE8}
              onChange={handleChange}
            />
          </div>
          <div className="mc-field-group">
            <button type="submit" name="subscribe" id="mc-embedded-subscribe" className="waitlist-button">
              JOIN THE TEAM.
            </button>
          </div>
          
          {/* Add the status message here with the className */}
          {statusMessage && <p className="status-message">{statusMessage}</p>}
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Waitlist;
